<template>
  <v-card
    class="overflow-hidden"
    flat
  >
    <v-app-bar
      absolute
      color="white"
      elevate-on-scroll
      scroll-target="#content"
    >
      <div class="d-flex flex-column">
        <div class="d-flex align-center">
          <v-toolbar-title
            class="text-h5 primary--text"
            v-text="title"
          />
          <v-chip
            v-if="status != null"
            :color="status ? 'success' : 'error'"
            class="overline mx-2"
            small
            v-text="status ? 'Ativo' : 'Inativo'"
          />
        </div>

        <label
          v-if="createdAt"
          class="body-2"
        >
          Cadastrado em {{ createdAt | formatDate }}
        </label>
      </div>

      <v-spacer />

      <v-btn
        color="primary"
        class="mr-3"
        rounded
        outlined
        @click="$emit('cancel')"
        v-text="'Cancelar'"
      />
      <v-btn
        v-if="showSaveButton"
        :disabled="disabledSave ? disabledSave : false"
        color="primary"
        rounded
        @click="$emit('save')"
        v-text="'Salvar'"
      />
    </v-app-bar>
    <v-sheet
      id="content"
      class="overflow-y-auto pt-10"
      max-height="88.5vh"
    >
      <v-container fluid>
        <slot />
      </v-container>
    </v-sheet>
  </v-card>
</template>

<script>
export default {
  props: {
    title: String,
    createdAt: String,
    showSaveButton: {
      type: Boolean,
      default: () => true,
    },
    status: {
      type: Boolean,
      default: () => {
        return null
      },
    },
    disabledSave: Boolean,
  },
}
</script>
